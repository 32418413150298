/* Retain the underline when selected AND focused */
button.MuiTab-root.Mui-selected:focus{
    outline: none;
    border-color:transparent;
}

/* Retain the underline when selected AND focused */
button.MuiTab-root.Mui-selected:focus > span{
    border-bottom: 4px solid #0266c8;
}

/* Remove the explicit white line between tabs*/
button.MuiTab-root {
    border-color: transparent;
}

a {
    color: #0266C8;
}